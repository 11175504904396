.header {
	height: 100vh;
	background-position: center;
	background: url("../Image/Hero-Banner.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
.position-header {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
}
.header-text {
	font-size: 52px;
	text-transform: uppercase;
	font-weight: bold;
	line-height: 70px;
	color: #fff;
}
.header-btn {
	background: #ec4899;
	color: #fff;
	padding: 18px 30px;
	font-size: 18px;
	border: none;
	transition: 0.3s;
	border-radius: 4px;
}
.header-btn:hover {
	background: #d6257d;
}
.header-pink {
	color: #ec4899;
}

@media (max-width: 450px) {
	.header-text {
		font-size: 40px;
	}
}
@media (max-width: 650px) {
	.header {
		background-position: center;
	}
	.position-img-left {
		position: absolute;
		left: 0%;
		bottom: 0%;
		z-index: 1;
		width: 200px;
	}
	.position-img-right {
		position: absolute;
		right: 0%;
		bottom: 0%;
		z-index: 1;
		width: 200px;
	}
}
@media (max-width: 315px) {
	.header-text {
		font-size: 14vw;
	}
}
