.logo-part {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	align-items: center;
}
.team-card {
	background: url("../Image//aqua-bg.png");
	transition: 0.3s;
	cursor: pointer;
}
.team-card:hover {
	transform: scale(1.03);
}
.team-card1 {
	background: url("../Image/purple-bg.png");
	transition: 0.3s;
	cursor: pointer;
}
.team-card1:hover {
	transform: scale(1.03);
}
.team-card2 {
	background: url("../Image/red-bg.png");
	transition: 0.3s;
	cursor: pointer;
}
.team-card2:hover {
	transform: scale(1.03);
}
.team-card3 {
	background: url("../Image/blue-bg.png");
	transition: 0.3s;
	cursor: pointer;
}
.team-card3:hover {
	transform: scale(1.03);
}

.team-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
}
.member-name {
	font-size: 28px;
}
.member-profession {
	font-size: 18px;
}
.twitter-account {
	font-size: 12px;
}
.twitter-account a {
	color: black;
	text-decoration: none;
	transition: 0.9s;
}
.twitter-account a:hover {
	text-decoration: underline;
	transform: scale(1.1);
}
.team {
	border-bottom: 1px solid rgba(255, 255, 255, 0.507);
}
.team-title,
.seen-title {
	font-size: 50px;
	font-weight: 500;
	text-transform: uppercase;
	color: white;
}
.group {
	font-size: 18px;
}
.team-details {
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: rgba(255, 255, 255, 0.56);
}
.team-details-pink {
	color: #ec4899;
}

@media (max-width: 990px) {
	.logo-part {
		grid-template-columns: 1fr 1fr;
	}
	.team-grid {
		grid-template-columns: 1fr 1fr;
	}
}
@media (max-width: 600px) {
	.logo-part {
		grid-template-columns: 1fr;
	}
	.team-grid {
		grid-template-columns: 1fr;
	}
}
@media (max-width: 450px) {
	.team-title {
		font-size: 40px;
	}
}
@media (max-width: 315px) {
	.team-title {
		font-size: 14vw;
	}
}
