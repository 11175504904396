body {
	background-color: rgb(26, 26, 26);

	background-size: cover;
	font-family: "Roboto", sans-serif !important;
	padding: 0px;
	margin: 0px;
}
.container {
	max-width: 1300px;
}

/* // SLIDER STYLING START */
.slider_container .slick-dots {
	bottom: -8px;
}

.slider_container .slider_max_width {
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
}

.slider_container .slick-prev:before {
	content: url("./Component/Image/arrowL.png");
	position: fixed;
	left: -4px;
	top: 50%;
	transform: translateY(-50%);
}

.slider_container .slick-next:before {
	content: url("./Component/Image/arrowR.png");
	position: fixed;
	right: -4px;
	top: 50%;
	transform: translateY(-50%);
}

.slider_container .slick-dots li.slick-active button:before,
.slider_container .slick-dots li button:before {
	color: #fff;
}

.slider_container .slick-dots li button:before {
	content: "-";
	font-size: 70px;
}

.slick-slide {
	padding: 0px 10px;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	cursor: grab;
}

.slick-slide img {
	cursor: grab;
}
