.withoutScroll {
	background-color: rgba(22, 22, 22, 0);
}
.scroll {
	background: #000;
	border-bottom: 1px solid rgba(255, 255, 255, 0.144);
}
.nav-bar-container {
	max-width: 1350px;
}
.navbar {
	padding: 0px !important;
}
/* Remove border from toggler */
.navbar-toggler {
	border: 0;
}

.container-fluid {
	padding: 0% !important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	outline: none;
	box-shadow: none;
	border: 0;
}

/* Lines of the Toggler */
.toggler-icon {
	width: 30px;
	height: 3px;
	background-color: #ffffff;

	display: block;
	transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar {
	margin: 5px auto;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
	transform: rotate(45deg);
	transform-origin: 10% 10%;
}
.affix {
	top: 0;
	width: 100%;
	z-index: 9999 !important;
}

.affix + .container-fluid {
	padding-top: 70px;
}
.navbar-toggler .middle-bar {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
	transform: rotate(-45deg);
	transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
	transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
	transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
	background-color: #ffffff;
}

.fa-discord,
.fa-twitter,
.fa-instagram {
	font-size: 23px;
	position: absolute;
	cursor: pointer;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.nav-link {
	color: #ffffff;
	font-weight: 600;
	text-transform: uppercase;
}
.nav-link:hover {
	color: #ec4899 !important;
}
.nav-link:focus,
.nav-link:hover {
	color: #ec4899 !important;
}
.icon-img {
	transition: 0.3s;
	cursor: pointer;
}
.icon-img:hover {
	transform: scale(1.07);
}
.bg-color {
	background-color: #000;
}
.logo2 {
	width: 210px;
}
@media (max-width: 1050px) {
	.logo2 {
		width: 130px;
	}
	.logo1 {
		width: 70px;
	}
}
