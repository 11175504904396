.faq {
	background: url("../Image/bg-story.png");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

.accordion-item {
	background-color: #191819;
}
.title-faq {
	color: white;
	font-weight: 700;
	font-size: 48px;
}
.faq-button {
	color: white;
	cursor: pointer;
	font-weight: 400;
	border-radius: 7px !important;
	font-size: 1rem;
	background-color: #191819;
	transform: 1s;
}
.accordion-items {
	background-color: #191819;
	border-radius: 5px;
}
.accordion-body {
	color: rgba(255, 255, 255, 0.56);
}
.border-faq {
	font-size: 18px;
	border-top: 1px solid white;
}
.accordion-body {
	font-size: 0.9rem;
	font-weight: 100;
	color: white;
}
.fa-minus {
	font-size: 30px;
	color: white;
	font-weight: 100;
}
.question {
	font-size: 20px;
	font-weight: 800;
}
.icon-relative {
	position: relative;
	height: 34px;
	width: 34px;
	background-color: white;
	border-radius: 50%;
}
.plus-icon {
	position: absolute;
	font-weight: 100;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #363838;
	font-size: 16px;
}
.minus-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-weight: 100;
	transform: translate(-50%, -50%);
	color: #363838;
	font-size: 16px;
}
.faq-container {
	max-width: 1000px;
}
.faq-left-position {
	position: absolute;
	bottom: 10%;
	left: 0%;
	width: 230px;
}
.faq-right-position {
	position: absolute;
	bottom: 10%;
	right: 0%;
	width: 230px;
	overflow-x: hidden;
}
@media (max-width: 450px) {
	.title-faq {
		font-size: 40px;
	}
}
@media (max-width: 400px) {
	.title-faq {
		font-size: 14vw;
	}
	.question {
		font-size: 18px;
	}
	.icon-relative {
		width: 30px;
		height: 30px;
	}
	.plus-icon {
		font-size: 16px;
	}
	.minus-icon {
		font-size: 16px;
	}
}
