.waifu-title {
	font-size: 37px;
	color: white;
	font-weight: 600;
}
.list-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.waifu-title-pink {
	color: #ec4899;
}
.waifu-details,
.list-grid {
	font-size: 20px;
	color: rgba(255, 255, 255, 0.56);
}
.waifu-details-pink {
	color: #ec4899;
}
.left-list ul li,
.right-list ul li {
	list-style-type: disc;
	line-height: 1.18;
}
.ranger-label-grid {
	font-size: 22px;
	color: white;
	font-weight: 700;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.ranger-number-last {
	text-align: center;
}
.ranger-number-last-one {
	text-align: right;
}
.ranger-label-text-grid {
	font-size: 18px;
	color: #c5c5c5;
	font-weight: 700;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
.ranger-text {
	text-align: center;
}
.pink-ranger {
	color: #ec4899;
}
.amount {
	font-size: 26px;
	color: #c5c5c5;
}
.link-part {
	font-size: 18px;
	text-decoration: none;
	text-align: center;
	color: white;
}
@media (max-width: 500px) {
	.ranger-label-text-grid {
		font-size: 12px;
	}
}
