.about {
	background: url("../Image//bg-story.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.about-title {
	font-size: 50px;
	font-weight: 500;
	text-transform: uppercase;
	color: white;
}
.about-details {
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: rgba(255, 255, 255, 0.56);
}
.about-details-pink {
	color: #ec4899;
}
.story-btn {
	background: #ec4899;
	color: #fff;
	padding: 18px 30px;
	font-size: 18px;
	border: none;
	border-radius: 4px;
	transition: 0.3s;
}
.grid-img {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
}
.story-img {
	cursor: pointer;
	background-color: transparent;
	transition: 0.3s;
}
.story-img:hover {
	cursor: pointer;

	transform: scale(1.03);
}
.story-btn:hover {
	background: #d6257d;
}
@media (max-width: 990px) {
	.grid-img {
		grid-template-columns: 1fr;
		margin: 0 auto;
	}
}
@media (max-width: 450px) {
	.about-title {
		font-size: 40px;
	}
}
@media (max-width: 315px) {
	.about-title {
		font-size: 14vw;
	}
}
