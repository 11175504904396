.title-pink {
	color: #ec4899;
	font-size: 50px;
	font-weight: 700;
	text-transform: uppercase;
}
.contact-us {
	font-size: 26px;
	color: white;
}
.border-top {
	border-top: 1px solid white;
}
.copy-right {
	font-size: 18px;
	color: rgba(255, 255, 255, 0.56);
}
