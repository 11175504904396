.roadmap {
	background: url("../Image/bg-story.png");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
.wrapper {
	max-width: 1080px;
	margin: 50px auto;
	padding: 0 20px;
	position: relative;
}
.wrapper .center-line {
	position: absolute;
	height: 90%;
	width: 4px;
	background: #fff;
	left: 50%;
	top: 20px;
	transform: translateX(-50%);
}
.wrapper .item {
	display: flex;
}
.wrapper .row-1 {
	justify-content: flex-start;
}
.wrapper .row-2 {
	justify-content: flex-end;
}
.wrapper .item section {
	border-radius: 5px;
	width: calc(50% - 40px);
	padding: 20px;
	position: relative;
}

.item section .icon,
.center-line .scroll-icon {
	position: absolute;
}
.center-line .scroll-icon {
	bottom: 0px;
	left: 50%;
	font-size: 25px;
	transform: translateX(-50%);
}
.row-1 section .icon {
	top: 15px;
	right: -75px;
	cursor: pointer;
	transition: 1s;
}
.row-2 section .icon {
	top: 15px;
	left: -75px;
	cursor: pointer;
	transition: 1s;
}
.row-1 section .icon:hover,
.row-2 section .icon:hover {
	transform: scale(1.1);
}
.item section .details .title {
	font-size: 26px;
	font-weight: 600;
	color: #fff;
}
.item section p {
	margin: 10px 0 17px 0;
	font-size: 18px;
	color: rgba(255, 255, 255, 0.56);
	text-align: justify;
}

@media (max-width: 990px) {
	.wrapper .center-line {
		left: 40px;
	}
	.wrapper .item {
		margin: 30px 0 3px 60px;
	}
	.wrapper .item section {
		width: 100%;
	}
	.row-2 section .icon {
		left: -110px;
	}
	.row-1 section .icon {
		left: -110px;
	}
}
@media (max-width: 850px) {
	.row-2 section .icon {
		left: -105px;
	}
	.row-1 section .icon {
		left: -105px;
	}
}
@media (max-width: 650px) {
	.row-2 section .icon {
		left: -95px;
	}
	.row-1 section .icon {
		left: -95px;
	}
}
@media (max-width: 550px) {
	.row-2 section .icon {
		left: -90px;
	}
	.row-1 section .icon {
		left: -90px;
	}
}
@media (max-width: 300px) {
	.wrapper .center-line,
	.item section::before,
	.item section .icon {
		display: none;
	}
	.wrapper .item {
		margin: 10px 0;
	}
}
